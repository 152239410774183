import React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import 'antd/dist/antd.css';
import Home from './Pages/Home';
import InfoForm from "./Pages/InfoForm";

function App() {
    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route path="/" element={<Home />} />
                {/*手机端填写信息*/}
                <Route path="/infoForm" element={<InfoForm />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
