// 判断是否布尔真
export function isTrue(value: BooleanValue): value is BooleanTrue {
    const TrueValueArray: any[] = [1, '1', true, 'true', 'True', 'TRUE'];
    return TrueValueArray.includes(value);
}

/**
 * 从错误对象中获取错误消息
 * @param e
 * @param defaultMessage
 */
export function errorMessage(
    e: any,
    defaultMessage: string = '发生未知错误',
): string {
    if (!e) return defaultMessage;
    const errCodes = [400, 401];

    if (e.response && !errCodes.includes(e.response.status))
        return '数据请求失败';
    if (e.isAxiosError && e.message === 'Network Error') return '网络异常';

    if (e?.response?.data?.data) {
        const firstValue = objectGetFirstValue(e.response.data.data);
        return errorMessage(firstValue);
    }

    if (
        errCodes.includes(e?.response?.status) &&
        (e?.response?.data?.message || e?.response?.data?.exception)
    ) {
        return e.response.data.message;
    }

    if (e instanceof Error && 'name' in e && e.name === 'ChunkLoadError')
        return '页面加载错误';

    if (e instanceof Error && e.message) return e.message;

    if (typeof e === 'string') return e;

    return defaultMessage;
}

// 将各种类型的值转成数值类型
export function toNumber(value: number | string | null | undefined) {
    if (!value) return 0;
    return Number(value) || 0;
}

/**
 * 把数字/数字字符约去成对小数
 * @param value
 * @param decimal
 */
export function toFixedNumber(value: string | number, decimal: number = 1) {
    return parseFloat(toNumber(value).toFixed(decimal));
}

/**
 * 活动时长文字
 * @param validityTime 分钟
 */
export function renderValidityTime(validityTime: number | undefined | null) {
    if (!validityTime) return '不限';
    if (validityTime > 60 * 24) return '超过一天';
    return `${validityTime}分钟`;
}

/**
 * 按长度截取字符串
 * @param str
 * @param len
 * @param dot
 */
export function strCut(str: string, len: number, dot: boolean = true) {
    return str.length > len ? str.substr(0, len) + (dot ? '...' : '') : str;
}

/**
 * html转换成纯文本
 * @param html
 * @param replaceFns
 */
export function htmlToPlain(
    html: string,
    ...replaceFns: ((html: string) => string)[]
) {
    const tempDivElement = document.createElement('div');
    let innerHTML = html;

    for (let fn of replaceFns) {
        innerHTML = fn(innerHTML);
    }

    tempDivElement.innerHTML = innerHTML;
    return tempDivElement.textContent || tempDivElement.innerText || '';
}

/**
 * 把html中图片标签替换成想要的内容
 */
export function replaceImgTag(html: string, replace: string = '[图片]') {
    return html.replaceAll(/<img [^>]*>/g, replace);
}

/**
 * 休眠 time毫秒
 * time: 毫秒
 */
export function sleep(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
}

/**
 * 加合
 * @param args
 */
export function sum(...args: number[]) {
    let num = 0;
    for (let i = 0; i < args.length; i++) {
        num += args[i];
    }
    return num;
}

/**
 * 索引数字转成A,B,C,D
 * @param index 数字, 从0开始
 */
export function indexToChar(index: number) {
    return String.fromCharCode(65 + index);
}

/**
 * 取得对象的第一个值
 * @param obj
 */
export function objectGetFirstValue(obj: Object) {
    const keys = Object.keys(obj);
    if (keys.length === 0) return null;
    return obj[keys[0] as keyof typeof obj];
}

/**
 * 文件尺寸格式化
 * @param bytes
 * @param dp
 */
export function humanFileSize(bytes: number, dp = 0) {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return bytes.toFixed(dp) + units[u];
}

/**
 * 把所有数据中包含的字符串, 清除两端空格
 * 注意: 该方法不会修改原对象的属性
 * @param data
 */
export function deepTrim<T>(data: T): T {
    if (data === null) return data;

    if (typeof data === 'string') {
        return data.trim() as unknown as T;
    }

    if (Array.isArray(data)) {
        return data.map(item => deepTrim(item)) as unknown as T;
    }

    if (typeof data === 'object') {
        const keys = Object.keys(data) as (keyof T)[];

        for (const key of keys) {
            data[key] = deepTrim(data[key]);
        }
        return data;
    }

    return data;
}

// px转vw
export function rpx(num: string | number) {
    const numNoUnit = toNumber(num);
    return (numNoUnit * 100) / 750 + 'vw';
}

/**
 * 深拷贝
 * @param data
 */
export function deepClone<T>(data: T): T {
    return JSON.parse(JSON.stringify(data));
}

/**
 * 是否为偶数
 * @param num
 */
export function isEven(num: number) {
    return num % 2 === 0;
}

/**
 * 字符串补齐
 * @param str 需要补齐的字符串
 * @param len
 * @param char
 */
export function padLeft(str: string, len: number, char: string) {
    if (char.length !== 1) throw new Error(`char need to be single char`);
    const padLen = Math.max(len - str.length, 0);
    const charList = new Array(padLen).fill('').map(() => char);
    return charList.join('') + str;
}
