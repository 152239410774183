import styles from './index.module.scss';
import React from 'react';


export default function MobileProductGoodness() {
    return (
        <div className={styles.mobileProductGoodness} id='mobileProductGoodness'>
            <div className={styles.productTitle}>
                <img className={styles.productGoodness} src={require('./img/productGoodness.png')}/>
            </div>
            <div className={styles.productCard}>
                <div className={styles.cardRow}>
                    <div className={styles.cardTitle}><img className={styles.cardIcon} src={require('./img/ysy.png')}/>易使用
                    </div>
                    <div className={styles.cardDetail}> 校友在 H5 侧注册校友后，通过浏览器自带功能“添加主屏幕快捷方式”或“添加桌面快捷方式”生成
                        H5
                        服务的入口，方便使用，不用二次登录。
                    </div>
                </div>
                <div className={styles.cardRow}>
                    <div className={styles.cardTitle}><img className={styles.cardIcon} src={require('./img/gaq.png')}/>高安全
                    </div>
                    <div
                        className={styles.cardDetail}>信息数据、服务器在学校统一管理，安全策略服务与数据做到前后端分离，数据库访问权限不对外网开放。
                    </div>
                </div>
                <div className={styles.cardRow}>
                    <div className={styles.cardTitle}><img className={styles.cardIcon} src={require('./img/yyy.png')}/>易运营
                    </div>
                    <div
                        className={styles.cardDetail}>提供多种多样活动模板，如秩年返校申请模板、寻根母校的分享海报模板等，加上积分任务奖励兑换功能，让运营工作轻松便捷。
                    </div>
                </div>
                <div className={styles.cardRow}>
                    <div className={styles.cardTitle}><img className={styles.cardIcon} src={require('./img/gxh.png')}/>个性化
                    </div>
                    <div className={styles.cardDetail}>根据学校各自要求，自定义维护H5 校友服务首页展示内容、咨询栏目、栏目内容等。
                    </div>
                </div>
                <div className={styles.cardRow}>
                    <div className={styles.cardTitle}><img className={styles.cardIcon} src={require('./img/fwy.png')}/>服务优
                    </div>
                    <div className={styles.cardDetail}>专业的服务团队、优质的产品研发，7x24小时全天候的服务。</div>
                </div>
            </div>
        </div>
    );
}
