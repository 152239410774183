import styles from './index.module.scss';
import React, {useState} from 'react';
import TopMenu from "./components/TopMenu";
import {useNavigate} from "react-router-dom";
import PhoneModal from "../../../../Components/PhoneModal";

interface Props {
    onButtonClick: (id: string) => void;//点击滚动
}

export default function MobileTop({onButtonClick}: Props) {

    const [active, setActive] = useState(false);//菜单显示隐藏
    const navigate = useNavigate();

    function close() {
        setActive(false);
    }

    function onFreeClick() {
        navigate("/infoForm");
    }

    return (
        <div className={styles.MobileTop}>
            <img className={styles.topBg} src={require('./img/bg.png')}/>
            <img className={styles.menuIcon} src={require('./img/menuIcon.png')} onClick={() => setActive(true)}/>
            {/*<img className={styles.freeButton} src={require('./img/freeButton.png')} onClick={onFreeClick}/>*/}
            {/*菜单*/}
            <TopMenu active={active} close={close} onButtonClick={onButtonClick}/>
            <div className={styles.freeButton}>
                <PhoneModal />
            </div>
        </div>
    );
}
