import styles from './index.module.scss';
import React from 'react';
import {Tabs} from 'antd-mobile';
import solutionData from "../../../../Services/solutionData.json";


export default function MobileSolution() {
    return (
        <div className={styles.solution} id='mobileSolution'>
            <div className={styles.solTitle}>
                <img className={styles.solutionTitle} src={require('./img/solutionTitle.png')}/>
            </div>

            <Tabs defaultActiveKey="1" activeLineMode='fixed'
                  style={{
                      '--fixed-active-line-width': '40px',
                      '--title-font-size': '14px',
                  }}>
                {
                    solutionData.map(item => <Tabs.Tab title={item.title} key={item.key}>
                        <div className={styles.tabDiv}>
                            <div className={styles.tabLeft}>
                                <div className={styles.leftTabTitle}>{item.title}</div>
                                <div className={styles.leftTabDetail}>
                                    {item.info}
                                </div>
                            </div>
                            <div className={styles.tabRight}>
                                <img className={styles.showImg1} src={require(`./img/${item.key}.png`)}/>
                            </div>
                        </div>
                    </Tabs.Tab>)
                }
            </Tabs>
        </div>
    );
}
