import styles from './index.module.scss';
import React from 'react';

interface Props {
    gotoTop:() => void;
}

export default function FixedButton({gotoTop}:Props) {
    return (
        <div className={styles.fixedDiv}>
            <div className={styles.buttonDiv}>
                <div className={styles.returnTop} onClick={() => gotoTop()}>
                    <div className={styles.returnDiv}>
                        <img className={styles.returnTopIcon} src={require('./img/returnTopIcon.png')}/>
                    </div>
                    <div className={styles.font} >
                        返回顶部
                    </div>
                </div>
                {/*<div className={styles.qrCodeDiv}>*/}
                {/*    <div className={styles.returnDiv}>*/}
                {/*        <img className={styles.qrCodeIcon} src={require('./img/qrcodeIcon.png')}/>*/}
                {/*    </div>*/}
                {/*    <div className={styles.font}>*/}
                {/*        关注我们*/}
                {/*        <div className={styles.bubble}>*/}
                {/*            <div className={styles.bubbleTitle}>扫描二维码</div>*/}
                {/*            <div className={styles.bubbleSubTitle}>关注我们</div>*/}
                {/*            <img className={styles.bubbleQrcode} src={require('./img/erwei.png')}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}
