import React from 'react';
import Styles from './index.module.scss'
import {Modal} from "antd";

const Index = () => {
    function onShow(){
        Modal.success({
            title:'申请试用',
            content:'请拨打电话 15904253500'
        })
    }
    return (
        <div className={Styles.root} onClick={onShow}>
            申请试用
        </div>
    );
};

export default Index;
