import styles from './index.module.scss';
import React from 'react';
import {Tabs} from 'antd';
import solutionData from '../../../../Services/solutionData.json'


export default function Solution() {
    return (
        <div className={styles.solution} id='Solution'>
            <div className={styles.solTitle}>
                <img className={styles.solutionTitle} src={require('./img/solutionTitle.png')}/>
            </div>

            <Tabs defaultActiveKey="0" centered tabBarGutter={80} animated={{tabPane: true}}>
                {
                    solutionData.map(item => <Tabs.TabPane tab={item.title} key={item.key}>
                        <div className={styles.tabDiv}>
                            <div className={styles.tabLeft}>
                                <div className={styles.leftTabTitle}>{item.title}</div>
                                <div className={styles.leftTabDetail}>
                                    {item.info}
                                </div>
                            </div>
                            <div className={styles.tabRight}>
                                <img className={styles.showImg1} src={require(`./img/${item.key}.png`)}/>
                            </div>
                        </div>
                    </Tabs.TabPane>)
                }

            </Tabs>
        </div>
    );
}
