import qs from 'qs';
import Request from 'Utils/Requests';

const BaseUrl = process.env.React_APP_BASE_URL!;

/**
 * 丁香集客户信息收集
 * @param params
 */



export function addCustomerContactInfo(params: {userName: string, companyName: string, phone:string,info:string}) {
    return Request.post<SuccessData<{result: boolean}>>(`${BaseUrl}/alumni/addCustomerContactInfo`, qs.stringify(params))
        .then(res => res.data);
}
