import styles from './index.module.scss';
import React from 'react';


export default function Wrap() {
    return (
        <div className={styles.wrap} id='Wrap'>
            <div className={styles.box1}>
                <div className={styles.box}>
                    <div className={styles.boxTitle}>自定义主题</div>
                    <div className={styles.detail}>H5 校友服务支持指定以维护主题色、logo 文件，打造学校专属轻量校友服务。
                    </div>
                </div>
            </div>
            <div className={styles.box1}>
                <div className={styles.box}>
                    <div className={styles.boxTitle}>校友数据精确、管理高效</div>
                    <div className={styles.detail}>校友数据数字化，随时随地高效管理校友会业务数据。</div>
                </div>
            </div>
            <div className={styles.box1}>
                <div className={styles.box}>
                    <div className={styles.boxTitle}>一切为了便于共享</div>
                    <div className={styles.detail}>全方位便捷分享，打通线上线下活动，增加校友的参与感。</div>
                </div>
            </div>
            <div className={styles.box1}>
                <div className={styles.box}>
                    <div className={styles.boxTitle}>校友一体化管理平台</div>
                    <div className={styles.detail}>提供多种多样活动模板，积分任务奖励兑换功能，让运营工作轻松便捷。</div>
                </div>
            </div>
        </div>
    );
}
