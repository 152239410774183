import styles from './index.module.scss';
import axios from 'axios';
import NavHeader from "./Components/NavHeader";
import Wrap from "./Components/Wrap";
import Solution from "./Components/Solution";
import ProductGoodness from "./Components/ProductGoodness";
import About from "./Components/About";
import Footer from "./Components/Footer";
import {useState} from "react";
import FixedButton from "./Components/FixedButton";
import DialogForm from "./Components/DialogForm/DialogForm";
import MobileTop from "./Components/MobileTop";
import MobileWrap from "./Components/MobileWrap";
import MobileSolution from "./Components/MobileSolution";
import MobileProductGoodness from "./Components/MobileProductGoodness";
import MobileAbout from "./Components/MobileAbout";
import MobileLine from "./Components/MobileLine";
import {Modal} from "antd";
import PhoneModal from "../../Components/PhoneModal";

export default function Home() {

    const [isFixedDiv, setIsFixedDiv] = useState(false);
    const [isShowDialog, setIsShowDialog] = useState(false);

    function gotoTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    window.addEventListener('scroll', () => {

        let wrap = document.getElementById('Wrap');
        // document.documentElement.scrollTop >700?setIsFixedDiv(true):setIsFixedDiv(false);
        if (wrap) {
            document.documentElement.scrollTop > wrap.offsetTop ? setIsFixedDiv(true) : setIsFixedDiv(false);
        }

    });

    //点击免费试用弹窗
    function onFreeClick() {
        // setIsShowDialog(true);
        Modal.success({
            title: '申请试用',
            content: '请拨打电话 15904253500'
        })
    }

    //点击按钮跳转锚点
    function onButtonClick(id: string) {
        let wrap = document.getElementById(id);
        if (wrap) {
            window.scroll({
                top: wrap.offsetTop - 100,
            });
        }
    }

    function close() {
        setIsShowDialog(false);
    }

    return (
        <div className={styles.root} id='root'>
            <div className={styles.pc}>
                {/*pc头部start*/}
                <div className={styles.topDiv}>
                    <div className={styles.greenBg}>
                        <div className={styles.imgDiv}>
                            <img className={styles.topBg} src={require('./img/green.png')}/>
                            <div className={styles.freeButton}>
                                <PhoneModal/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.blueBg}>
                        <div className={styles.imgDiv}>
                            <img className={styles.topBg2} src={require('./img/blue.png')}/>
                        </div>
                    </div>
                    <NavHeader onFreeClick={onFreeClick} onButtonClick={onButtonClick} onGoTop={gotoTop}/>

                </div>
                {isFixedDiv && (
                    <NavHeader isFixedDiv={isFixedDiv} onFreeClick={onFreeClick} onButtonClick={onButtonClick}
                               className={styles.fixedHeader} onGoTop={gotoTop}/>
                )}
                {/*pc四个卡牌效果start*/}
                <Wrap/>
                {/*解决方案start*/}
                <Solution/>
                {/*产品优势start*/}
                <ProductGoodness/>
                {/*关于start*/}
                <About/>
                {/*footer*/}
                <Footer onButtonClick={onButtonClick}/>

                {/*浮动回到顶部、二维码*/}
                {isFixedDiv && (
                    <FixedButton gotoTop={gotoTop}/>
                )}

                {/*弹窗填信息*/}
                <DialogForm show={isShowDialog} onClose={close}/>
            </div>
            <div className={styles.mobile}>
                {/*头部免费试用，菜单*/}
                <MobileTop onButtonClick={onButtonClick}/>
                {/*四个卡牌效果*/}
                <MobileWrap/>
                {/*解决方案*/}
                <MobileSolution/>
                {/*产品优势*/}
                <MobileProductGoodness/>
                {/*关于*/}
                <MobileAbout/>
                {/*联系人*/}
                <MobileLine/>
            </div>

        </div>
    );
}
