import styles from './index.module.scss';
import React from 'react';
import classNames from "classnames";

interface Props {
    active:boolean;//菜单显示隐藏
    close:() => void;//隐藏
    onButtonClick:(id:string) => void;//点击滚动
}

export default function TopMenu({active, close, onButtonClick}:Props) {
    return (
        <div className={`${styles.TopMenu} ${active?styles.hidden:styles.active}`}>
            <div className={styles.content}>
                <img className={styles.close} src={require('./img/close.png')} onClick={close}/>
                <div className={styles.buttonRow} onClick={() => {onButtonClick('mobileWrap');close();}}>产品</div>
                <div className={styles.buttonRow} onClick={() => {onButtonClick('mobileSolution');close();}}>解决方案</div>
                <div className={styles.buttonRow} onClick={() => {onButtonClick('mobileProductGoodness');close();}}>产品优势</div>
                <div className={styles.buttonRow} onClick={() => {onButtonClick('mobileAbout');close();}}>关于</div>
                <div className={styles.buttonRow} onClick={() => {onButtonClick('mobileLine');close();}}>联系</div>
            </div>
        </div>
    );
}
