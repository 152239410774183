/**
 * 读写存储
 * @author 孟浩
 *
 * 使用方法:
 * import Storage from '../Lib/Storage';
 * Storage.set("userInfo", { userName:xxx, ... });
 * Storage.get<UserInfo>("userInfo");  // 返回类型为 UserInfo | undefined
 */

const getSecretValue = (key: string) => btoa(key);

const setNewValue = (key: string, newValue: any) => {
    localStorage.setItem(getSecretValue(key), secretData(newValue));
};

const getStoreValue = <T>(key: string) => {
    const value = localStorage.getItem(getSecretValue(key));
    return restoreData<T>(value);
};

const secretData = (data: any): string => {
    return btoa(encodeURIComponent(JSON.stringify(data)));
};

const restoreData = <T>(value: string | null) => {
    if (typeof value === 'undefined' || value === null) return value;
    return JSON.parse(decodeURIComponent(atob(value))) as T | null;
};

const removeValue = (key: string) => {
    localStorage.removeItem(getSecretValue(key));
};

const each = (callback: (val: any, namespacedKey: string) => void) => {
    localStorage.each((val: any, key: any) => {
        // 有些第三方插件可能会写入localStorage导致base64解码错误
        try {
            callback(restoreData(val), atob(key));
        } catch (e) {}
    });
};

const clear = () => localStorage.clear();

const Storage = {
    set: setNewValue,
    get: getStoreValue,
    remove: removeValue,
    each,
    clear,
};

export default Storage;
