import styles from './index.module.scss';
import React from 'react';


export default function MobileLine() {
    return (
        <div className={styles.MobileLine} id='mobileLine'>
            <div className={styles.aboutTitle}>
                <img className={styles.lineImg} src={require('./img/line.png')}/>
            </div>
            <div className={styles.content}>
                <div className={styles.rowLine}>售后服务：0411-84720210</div>
                <div className={styles.rowLine}>联系电话：15904253500</div>
                <div className={styles.rowLine}>工作时间：8:00 - 17:00（工作日）</div>
            </div>
            <div className={styles.line}/>
            <div className={styles.footer}>
                <a href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2024019112号</a>
                Copyright © www.biyela.cn, All Rights Reserved. <br/> 大连东财文华教育科技有限公司
            </div>

        </div>
    );
}
