import styles from './index.module.scss';
import React from 'react';

interface Props {
    onButtonClick: (id: string) => void;//点击按钮
}

export default function Footer({onButtonClick}: Props) {
    return (
        <div>
            <div className={styles.Footer} id='Footer'>
                <div className={styles.footerContent}>
                    <div className={styles.lineDiv}>
                        <img className={styles.footerLogo} src={require('./img/logo.png')}/>
                        <div className={styles.logoTip}>专注服务广大校友一站式服务平台</div>
                    </div>
                    <div className={styles.lineDiv}>
                        <div className={styles.title}>网站栏目</div>
                        <div className={styles.buttonDiv}>
                            <div className={styles.button} onClick={() => onButtonClick('Wrap')}>产品</div>
                            <div className={styles.button} onClick={() => onButtonClick('About')}>关于</div>
                            <div className={styles.button} onClick={() => onButtonClick('Solution')}>解决方案</div>
                            <div className={styles.button} onClick={() => onButtonClick('Footer')}>联系方式</div>
                            <div className={styles.button} onClick={() => onButtonClick('ProductGoodness')}>产品优势
                            </div>
                        </div>
                    </div>
                    <div className={styles.lineDiv}>
                        <div className={styles.title}>服务与支持</div>
                        <div className={styles.subFont}>售后服务：0411-84720210</div>
                        <div className={styles.subFont}>工作时间：8:00 - 17:00（工作日）</div>
                    </div>
                    <div className={styles.qrLineDiv}>
                        <div className={styles.phoneTitle}>电话咨询</div>
                        <div className={styles.phone}>15904253500</div>
                        {/*<div className={styles.qrcodeDiv}>*/}
                        {/*    <div className={styles.qrDiv}>*/}
                        {/*        <img className={styles.qrcodeImg} src={require('./img/erwei.png')}/>*/}
                        {/*        <div className={styles.qrcodeFont}>微信二维码</div>*/}
                        {/*    </div>*/}
                        {/*    <div className={styles.qrDiv}>*/}
                        {/*        <img className={styles.qrcodeImg} src={require('./img/erwei.png')}/>*/}
                        {/*        <div className={styles.qrcodeFont}>公众号二维码</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className={styles.copyRight}>
                <a href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2024019112号</a>
                Copyright © www.biyela.cn, All Rights Reserved. 大连东财文华教育科技有限公司
            </div>
        </div>

    );
}
