import styles from './index.module.scss';
import axios from 'axios';
import {Button, Form, Input, Dialog, TextArea } from "antd-mobile";
import React from "react";
import {useForm} from "antd/es/form/Form";
import useRequestFn from "../../Utils/Hooks/useRequestFn";
import {addCustomerContactInfo} from "../../Services/main";
import {errorMessage} from "../../Utils/common";
import {useNavigate} from "react-router-dom";

export default function InfoForm() {
    const [form] = useForm();
    const navigate = useNavigate();
    const formRules = {
        userName:{required: true, message: `姓名不能为空`},
        companyName:{required: true, message: `学校名称不能为空`},
        phone:{required: true,pattern: /^1[3|4|5|6|7|8|9]\d{9}$/, message: "手机号长度11位，以13/14/15/16/17/18/19开头"}
    };
    const [save, {loading}] = useRequestFn(addCustomerContactInfo);

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
    }

    async function onFinish(val:any) {
        console.log(val);

        try {
            await save({...val});
            Dialog.alert({
                content: '提交成功',
                onConfirm: () => {
                    navigate('/');
                }});

        } catch (e) {
            Dialog.alert({
                content: errorMessage(e),
                onConfirm: () => {
                }
            })
        }
    }

    return (
        <div className={styles.root}>
            <img className={styles.headerImg} src={require('./img/header.png')}/>
            <div className={styles.dialogForm}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    className={styles.form}
                    layout='horizontal'
                    footer={<div className={styles.button}>
                        <Button block type='submit' color='primary' size='middle'>
                            提交
                        </Button>
                    </div>}
                >
                    <Form.Item
                        name="companyName"
                        label={`学校名称：`}
                        rules={[formRules.companyName]}
                        initialValue={''}
                    >
                        <Input placeholder={`请输入学校名称`} maxLength={50} style={{ '--text-align': 'right' }}/>
                    </Form.Item>
                    <Form.Item
                        name="userName"
                        label={`联系人姓名：`}
                        rules={[formRules.userName]}
                        initialValue={''}
                    >
                        <Input placeholder={`请输入联系人姓名`} maxLength={20} style={{ '--text-align': 'right' }}/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={`联系人电话：`}
                        rules={[formRules.phone]}
                        initialValue={''}
                    >
                        <Input placeholder={`请输入联系人电话`} maxLength={11} style={{ '--text-align': 'right' }}/>
                    </Form.Item>
                    <Form.Item
                        name="info"
                        label={`备注：`}
                        initialValue={''}
                        layout={'vertical'}
                    >
                        <TextArea autoSize={{minRows:3}} placeholder={`您可以备注您方便接听电话的时间段，我们会在您方便的时候给您电话。`} maxLength={100}/>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}
