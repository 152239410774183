import styles from './index.module.scss';
import React from 'react';


export default function About() {
    return (
        <div className={styles.About} id='About'>
            <div className={styles.aboutTitle}>
                <img className={styles.aboutImg} src={require('./img/about.png')}/>
            </div>
            <div className={styles.flexDiv}>
                <div className={styles.leftDiv}>
                    <div className={styles.rowFont}>
                        大连东财文华教育科技有限公司成立于 2003 年，专注为高校校友会提供解决方案，协助校友处推进校友组织的蓬勃发展。
                    </div>
                    <div className={styles.rowFont}>
                        毕业啦是大连东财文华教育科技有限公司研发的轻量级校友 SaaS 服务，由 H5
                        校友服务和校友服务管理两个平台构成，产品通过提供历史数据和组织机构的维护、校友申请及校友组织活动的审核、咨询和新闻内容的发布、权限分配等功能，解决校友处组织、活动以及运营的一些列问题，更大程度提高各校管理运营校友云的能力和效率。
                    </div>
                </div>
                <div className={styles.rightDiv}>
                    <img className={styles.rightImg} src={require('./img/aboutImg.png')}/>
                </div>
            </div>
        </div>
    );
}
